/* eslint-disable react/no-danger */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */

import React, { useEffect, useRef, useState } from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Expander, Grid, Image } from "~components";
import { useApp, useWindowDimensions } from "~hooks";
import {
  GRID_GAP_REM,
  GRID_PADDING_REM
} from "~components/_common/Grid/index.jsx";
import { MEDIA_QUERIES } from "~utils/css";
import { capitalizeAllWords } from "~utils/helpers";

const Wrapper = styled.section`
  width: 100%;
  position: relative;
`;

const Background = styled.div`
  width: calc(100% + ${GRID_PADDING_REM}rem);
  height: calc(100% + 1px);
  position: absolute;
  top: -1px;
  bottom: 0;
  left: -${GRID_PADDING_REM / 2}rem;

  ${MEDIA_QUERIES?.desktop} {
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
`;

const Container = styled.div`
  height: 100%;
  position: relative;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: left;

  ${MEDIA_QUERIES?.desktop} {
    padding: 0 1rem 0;
  }
`;

const Figure = styled.figure`
  width: 100%;
  position: relative;

  ${MEDIA_QUERIES?.desktop} {
    width: calc(100% + ${GRID_PADDING_REM}rem);
  }
`;

const OverlayFigure = styled.figure`
  // transition: 0.3s var(--cubic-easing) opacity;

  opacity: ${({ active }) => (active ? 1 : 0)};

  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  overflow: hidden;

  ${MEDIA_QUERIES?.desktop} {
    width: calc(100% + ${GRID_PADDING_REM}rem);
  }
`;

const ContentBlock = styled.article`
  grid-column: span 12 / span 12;
  position: relative;

  ${MEDIA_QUERIES?.desktop} {
    grid-column: span 6 / span 6;
  }
`;

/** ============================================================================
 * @component
 * Sample component receiving heading and body text.
 */
const ExpanderArea = ({
  data: {
    backgroundColor,
    fontColor,
    heading,
    items,
    image,
    imageAlignment = `left`,
    text
  }
}) => {
  // ---------------------------------------------------------------------------
  // context / ref / state

  const { pathname } = useApp();
  const { isDesktop } = useWindowDimensions();

  const imageRef = useRef();

  const [activeTag, setActiveTag] = useState(null);
  const [overlayImage, setOverlayImage] = useState(null);
  const [expandedIndex, setExpandedIndex] = useState(null);

  const tags = [];

  if (items?.[0]) {
    items.forEach((item) => {
      if (!item?.tags?.[0]) {
        return;
      }

      item.tags.forEach((tag) => {
        tag = tag.toLowerCase();

        if (!tags?.includes(tag)) {
          tags.push(tag);
        }
      });
    });
  }

  // ---------------------------------------------------------------------------
  // lifecycle

  useEffect(() => {
    if (activeTag) {
      setExpandedIndex(null);
    }
  }, [activeTag]);

  // ---------------------------------------------------------------------------
  // render

  return (
    <Wrapper>
      {heading && heading !== `` && (
        <div
          css={css`
            width: 100%;
            position: relative;
            padding: 0.85rem 0;
            background: ${backgroundColor?.hex};
            color: ${fontColor?.hex};

            ${MEDIA_QUERIES?.desktop} {
              padding: 1.25rem 0;
            }
          `}
        >
          <Grid>
            <header
              css={css`
                grid-column: span 12 / span 12;
              `}
            >
              <h2 className="b1">{heading}</h2>
            </header>
          </Grid>
        </div>
      )}

      <div
        css={css`
          width: 100%;
          position: relative;
          overflow: hidden;
        `}
      >
        <Background
          css={css`
            background: ${backgroundColor?.hex};
          `}
          imageAlignment={imageAlignment}
        />

        <Grid>
          <ContentBlock
            css={css`
              order: 1;

              ${MEDIA_QUERIES?.desktop} {
                order: ${imageAlignment === `left` ? `2` : `1`};
              }
            `}
          >
            <Container>
              {text && text !== `` && (
                <header
                  css={css`
                    width: 100%;
                    position: absolute;
                    top: 0;
                    right: 0;
                    z-index: 10;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding-top: 0.5rem;
                    padding-bottom: 0.5rem;
                    background: white;

                    ${MEDIA_QUERIES?.desktop} {
                      padding-top: 6rem;
                    }
                  `}
                >
                  <h2
                    css={css`
                      text-align: center;
                    `}
                    className="b3"
                  >
                    {text}
                  </h2>

                  {tags?.[0] && (
                    <div
                      css={css`
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        white-space: pre-wrap;
                      `}
                    >
                      <p className="b3">:{` `}</p>

                      {tags.map((tag, tagIndex) => (
                        <button type="button" onClick={() => setActiveTag(tag)}>
                          <p
                            css={css`
                              font-style: ${tag?.toLowerCase() ===
                              activeTag?.toLowerCase()
                                ? `italic`
                                : `normal`};
                            `}
                            className="b3"
                          >
                            {capitalizeAllWords(tag)}
                            {tagIndex < tags?.length - 1 ? `, ` : ``}
                          </p>
                        </button>
                      ))}
                    </div>
                  )}
                </header>
              )}

              {items?.[0] && (
                <ul
                  css={[
                    css`
                      padding: 6.5rem 0;

                      ${MEDIA_QUERIES?.desktop} {
                        padding: 0;
                      }

                      ${MEDIA_QUERIES.hoverable} {
                        &:hover {
                          li {
                            opacity: 0.3;
                          }
                        }
                      }
                    `
                  ]}
                >
                  {items.map((item, itemIndex) => {
                    let matchesTag = activeTag === null;

                    if (!matchesTag && item?.tags?.[0]) {
                      item.tags.forEach((tag) => {
                        if (matchesTag) {
                          return;
                        }

                        if (tag?.toLowerCase() === activeTag?.toLowerCase()) {
                          matchesTag = true;
                        }
                      });
                    }

                    if (!matchesTag) {
                      return <></>;
                    }

                    const active = expandedIndex === itemIndex;

                    let opacity = 1;

                    if (expandedIndex !== null) {
                      opacity = active ? 1 : 0.3;
                    }

                    //

                    return (
                      <li
                        key={`expander-${item.label}`}
                        css={css`
                          transition: 0.3s var(--cubic-easing) opacity;

                          color: ${fontColor?.hex || `#000000`};

                          ${MEDIA_QUERIES.hoverable} {
                            &:hover {
                              opacity: 1 !important;
                            }
                          }
                        `}
                      >
                        <Expander
                          active={active}
                          onClick={() => {
                            if (active) {
                              setExpandedIndex(null);
                              setOverlayImage(null);
                            } else {
                              setExpandedIndex(active ? null : itemIndex);
                              setOverlayImage(
                                item?.image?.asset ? item.image : null
                              );
                            }
                          }}
                          label={item.label}
                          labelOpacity={opacity}
                          content={() => (
                            <p
                              css={css`
                                white-space: pre-wrap;

                                a {
                                  text-decoration: underline;
                                }
                              `}
                              className="b3"
                            >
                              <span
                                dangerouslySetInnerHTML={{ __html: item.body }}
                              />
                            </p>
                          )}
                        />
                      </li>
                    );
                  })}
                </ul>
              )}
            </Container>
          </ContentBlock>

          <ContentBlock
            css={css`
              width: calc(100% + ${GRID_PADDING_REM}rem);
              left: -${GRID_PADDING_REM / 2}rem;
              order: ${pathname?.includes(`faqs`)
                ? `0`
                : `1`}; // This is super hacky...

              ${MEDIA_QUERIES?.desktop} {
                width: calc(100% + ${GRID_GAP_REM / 2}rem);
                order: ${imageAlignment === `left` ? `1` : `2`};

                ${imageAlignment === `right`
                  ? `left: auto; right: ${GRID_GAP_REM / 2}rem;`
                  : `left: -${GRID_PADDING_REM}rem`};
              }
            `}
          >
            <Figure ref={imageRef}>
              <Image
                css={css`
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                `}
                image={image}
              />
            </Figure>

            <OverlayFigure active={overlayImage !== null}>
              <Image
                css={css`
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                `}
                image={overlayImage}
              />
            </OverlayFigure>
          </ContentBlock>
        </Grid>
      </div>
    </Wrapper>
  );
};

export default ExpanderArea;
